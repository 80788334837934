"use strict";
exports.__esModule = true;
var storageNamespace = "console-game";
var DataStorage = /** @class */ (function () {
    function DataStorage() {
        this.storage = window.localStorage;
    }
    DataStorage.get = function () {
        if (!DataStorage.instance) {
            DataStorage.instance = new DataStorage();
        }
        return DataStorage.instance;
    };
    DataStorage.prototype.setSave = function (save) {
        this.storage.setItem(storageNamespace + ".save", JSON.stringify(save));
    };
    DataStorage.prototype.getSave = function () {
        return JSON.parse(this.storage.getItem(storageNamespace + ".save"));
    };
    DataStorage.prototype.deleteSave = function () {
        this.storage.removeItem(storageNamespace + ".save");
    };
    DataStorage.prototype.isSaveExsit = function () {
        return this.storage.getItem(storageNamespace + ".save") === null ? false : true;
    };
    return DataStorage;
}());
exports["default"] = DataStorage;
