"use strict";
exports.__esModule = true;
var lib_1 = require("./lib");
var types_1 = require("./types");
var vars_1 = require("./types/vars");
var DataStorage_1 = require("./DataStorage");
var nriot_utils_1 = require("nriot-utils");
var emptyFunc = function () { };
var key_handles = {
    W: emptyFunc,
    A: emptyFunc,
    S: emptyFunc,
    D: emptyFunc
};
var Game = /** @class */ (function () {
    /**
     * The constructor is for debugging
     * @constructor
     */
    function Game() {
        this.isBeginPrtc = false; // notice: Don't delete this variable
        this.space = types_1.Space.BEGIN;
        this.level = 0;
        this.money = 0;
        this.weapon = lib_1.Lib.getWeapon(0);
        this.armor = {
            head: {},
            chest: {},
            boots: {}
        };
        this.mobs = [];
        this.bossHeart = lib_1.Lib.getMob(4).heart;
        this.bossPosition = types_1.Position.NORTH;
        // dev debug
        // globalThis["dev_level"] = (a: number) => {this.giveLevel(a)};
        // globalThis["dev_money"] = (a: number) => {this.giveMoney(a)};
    }
    /**================================================================================
     * Game Status
     * ================================================================================
     */
    Game.prototype.gameBegin = function () {
        var _this = this;
        var storage = DataStorage_1["default"].get();
        if (storage.isSaveExsit()) {
            var save = storage.getSave();
            this.money = save.money;
            this.level = save.level;
            this.weapon = save.weapon;
            this.armor = save.armor;
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("text.save_detected"));
            console.log((0, lib_1.text)("command.beg_prtc.explaination"));
            lib_1.Lib.setCommand("g_prtc", function () { lib_1.Lib.delCommand("g_main"); _this.prtcMission(); });
            lib_1.Lib.setCommand("g_main", function () { lib_1.Lib.delCommand("g_prtc"); _this.mainMission(); });
            return;
        }
        if (!lib_1.Lib.isGameBegin) {
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("text.npc1"));
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.guide"), (0, lib_1.text)("text.npc2"));
            lib_1.Lib.warnMessage((0, lib_1.text)("text.npc3"));
            lib_1.Lib.errMessage((0, lib_1.text)("text.npc4"));
            lib_1.Lib.tips((0, lib_1.text)("tip.start_game"));
            lib_1.Lib.setCommand("yes", function () { _this.startMission(); });
        }
        else {
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("text.npc5"));
        }
    };
    Game.prototype.gameOver = function () {
        if (lib_1.Lib.isGameBegin) {
            lib_1.Lib.isGameBegin = false;
            this.space = types_1.Space.END;
            lib_1.Lib.delCommand("g_main");
            clearInterval(this.bossAttack);
            this.overStory();
            lib_1.Lib.tips((0, lib_1.text)("tip.restart"));
            lib_1.Lib.setCommand("restart", function () { window.location.reload(); });
        }
    };
    Game.prototype.playerDeath = function (by) {
        var _this = this;
        this.space = types_1.Space.DEATH;
        lib_1.Lib.delCommand("g_main");
        clearInterval(this.mobSpawner);
        console.log((0, lib_1.text)("message.death").replace("%s", by));
        lib_1.Lib.tips((0, lib_1.text)("tip.respawn"));
        lib_1.Lib.setCommand("respawn", function () {
            _this.mainMission();
            lib_1.Lib.delCommand("respawn");
        });
    };
    /**================================================================================
     * Game Stories
     * ================================================================================
     */
    Game.prototype.startStory = function () {
        console.log((0, lib_1.text)("text.plot1"));
        lib_1.Lib.warnMessage((0, lib_1.text)("text.plot2"));
        console.log((0, lib_1.text)("text.plot3"));
        lib_1.Lib.npcSpeak((0, lib_1.text)("npc.little_bit"), (0, lib_1.text)("text.npc6"));
        lib_1.Lib.npcSpeak((0, lib_1.text)("npc.old_bit"), (0, lib_1.text)("text.npc7"));
        lib_1.Lib.npcSpeak((0, lib_1.text)("npc.little_bit"), (0, lib_1.text)("text.npc8"));
        console.log((0, lib_1.text)("text.plot4"));
    };
    Game.prototype.overStory = function () {
        console.log((0, lib_1.text)("text.plot5"));
        console.log("%c" + (0, lib_1.text)("text.game_finish"), "font-size: 17px; font-weight: bold");
    };
    Game.prototype.bossStory = function () {
        console.log((0, lib_1.text)("text.plot6").replace("%s", this.weapon.name));
        lib_1.Lib.npcSpeak((0, lib_1.text)("npc.little_bit"), (0, lib_1.text)("text.npc9"));
        // Chinese to Binary
        // Chinese: 你是阻止不了我的!
        lib_1.Lib.npcSpeak((0, lib_1.text)("mob.super_code_mob"), "111001001011110110100000 111001101001100010101111 111010011001100010111011 111001101010110110100010 111001001011100010001101 111001001011101010000110 111001101000100010010001 111001111001101010000100 00100001");
        console.log((0, lib_1.text)("text.plot7"));
    };
    /**================================================================================
     * Missions
     * ================================================================================
     */
    Game.prototype.startMission = function () {
        var _this = this;
        lib_1.Lib.delCommand("yes");
        this.startStory();
        lib_1.Lib.tips((0, lib_1.text)("tip.beg_prtc"));
        lib_1.Lib.setCommand("beg_prtc", function () {
            _this.isBeginPrtc = true;
            console.log((0, lib_1.text)("command.beg_prtc.explaination"));
            _this.giveWeapon(1);
            _this.giveLevel(1);
            lib_1.Lib.setCommand("g_prtc", function () { lib_1.Lib.delCommand("g_main"); _this.prtcMission(); });
            lib_1.Lib.setCommand("g_main", function () { lib_1.Lib.delCommand("g_prtc"); _this.mainMission(); });
            lib_1.Lib.delCommand("beg_prtc");
        });
    };
    Game.prototype.mainMission = function () {
        var _this = this;
        if (this.space != types_1.Space.MAIN) {
            this.space = types_1.Space.MAIN;
            this.mobs = [];
            clearInterval(this.mobSpawner);
            lib_1.Lib.groupMessage((0, lib_1.text)("group.hub"), [
                (0, lib_1.text)("text.g_prtc"),
                (0, lib_1.text)("text.g_shop")
            ]);
            lib_1.Lib.delCommand("g_main");
            lib_1.Lib.setCommand("g_prtc", function () { _this.prtcMission(); });
            lib_1.Lib.setCommand("g_shop", function () { _this.shopMission(); });
            lib_1.Lib.keysListener(key_handles);
        }
    };
    Game.prototype.prtcMission = function () {
        var _this = this;
        var common_handle = function (dir) {
            for (var i = 0; i < _this.mobs.length; i++) {
                if (_this.mobs[i].dir == dir) {
                    // Crit damage (25%)
                    if (lib_1.Lib.randomBoolean(3)) {
                        _this.mobs[i].heart -= 2;
                        lib_1.Lib.npcSpeak(_this.mobs[i].name, (0, lib_1.text)("mob." + _this.mobs[i].type + ".crit")[nriot_utils_1.NUtils.getRandom(0, 2)]);
                    }
                    // Common damage
                    _this.mobs[i].heart -= _this.weapon.att;
                    if (_this.mobs[i].heart > 0) {
                        lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("message.attack").replace("%s", _this.mobs[i].heart.toString()));
                    }
                    else {
                        lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("message.kill"));
                        _this.killMobLevel(_this.mobs[i].id);
                        nriot_utils_1.NUtils.arrayItemDelete(_this.mobs, i);
                    }
                    break;
                }
            }
        };
        if (this.space != types_1.Space.PRACTICE) {
            this.space = types_1.Space.PRACTICE;
            this.mobs = [];
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("text.npc10"));
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("text.npc11"));
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("text.npc12"));
            lib_1.Lib.delCommand("g_prtc");
            lib_1.Lib.delCommand("g_shop");
            lib_1.Lib.setCommand("g_main", function () { _this.mainMission(); });
            lib_1.Lib.keysListener({
                W: function () { return common_handle(types_1.Direction.UP); },
                A: function () { return common_handle(types_1.Direction.LEFT); },
                S: function () { return common_handle(types_1.Direction.DOWN); },
                D: function () { return common_handle(types_1.Direction.RIGHT); }
            });
            this.mobSpawner = setInterval(function () {
                if (_this.mobs.length <= 7) {
                    _this.spawnMob();
                }
                else {
                    _this.playerDeath((0, lib_1.text)("mob.code_mob"));
                }
            }, 3000);
        }
    };
    Game.prototype.shopMission = function () {
        var _this = this;
        if (this.space != types_1.Space.SHOP) {
            this.space = types_1.Space.SHOP;
            var items = lib_1.Lib.getItemList();
            var switcher = 0;
            lib_1.Lib.delCommand("g_shop");
            lib_1.Lib.delCommand("g_prtc");
            lib_1.Lib.setCommand("g_main", function () {
                _this.mainMission();
                lib_1.Lib.delCommand("select");
                lib_1.Lib.delCommand("buy");
            });
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("text.npc13"));
            lib_1.Lib.groupMessage((0, lib_1.text)("group.shop"), function () {
                var it = [];
                for (var i in items) {
                    if (items[i].name != "") {
                        it.push(items[i].name);
                    }
                }
                return it;
            });
            lib_1.Lib.tips((0, lib_1.text)("tip.shop"));
            lib_1.Lib.setCommand("select", function () {
                if (switcher < items.length - 1) {
                    switcher++;
                }
                else {
                    switcher = 0;
                }
                console.log((0, lib_1.text)("message.selected").replace("%s", items[switcher].name), "color: yellow");
            });
            lib_1.Lib.setCommand("buy", function () {
                if (items[switcher].price <= _this.money) {
                    _this.money -= items[switcher].price;
                    _this.giveWeapon(items[switcher].id);
                    lib_1.Lib.npcSpeak((0, lib_1.text)("npc.shop"), (0, lib_1.text)("message.bought").replace("%s", _this.money.toString()));
                }
                else {
                    lib_1.Lib.npcSpeak((0, lib_1.text)("npc.shop"), (0, lib_1.text)("message.cant_afford"));
                }
            });
        }
    };
    Game.prototype.bossMission = function () {
        var _this = this;
        var updatePanel = function () {
            vars_1.boss_panel.PANEL.style.display = "block";
            vars_1.boss_panel.P_HEART.innerHTML = player_heart.toString();
            vars_1.boss_panel.B_HEART.innerHTML = _this.bossHeart.toString();
        };
        var attack_flag = false;
        var common_handle = function (position) {
            if (_this.bossPosition == position && _this.bossHeart > 0) {
                _this.bossHeart -= (_this.weapon.att - 2);
                attack_flag = true;
                updatePanel();
            }
            else if (_this.bossPosition == position) {
                _this.gameOver();
            }
        };
        if (this.space != types_1.Space.BOSS) {
            this.space = types_1.Space.BOSS;
            this.bossStory();
            var boss = lib_1.Lib.getMob(4);
            var player_heart = 30;
            this.bossPosition = types_1.Position.NORTH;
            updatePanel();
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("text.npc14"));
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("text.npc11"));
            lib_1.Lib.keysListener({
                W: function () { return common_handle(types_1.Position.NORTH); },
                D: function () { return common_handle(types_1.Position.EAST); },
                S: function () { return common_handle(types_1.Position.SOUTH); },
                A: function () { return common_handle(types_1.Position.WEST); }
            });
            this.bossAttack = setInterval(function () {
                var position = nriot_utils_1.NUtils.getRandom(0, 3);
                _this.bossPosition = position;
                switch (position) {
                    case 0:
                        lib_1.Lib.errMessage((0, lib_1.text)("text.boss.north"));
                        break;
                    case 1:
                        lib_1.Lib.errMessage((0, lib_1.text)("text.boss.east"));
                        break;
                    case 2:
                        lib_1.Lib.errMessage((0, lib_1.text)("text.boss.south"));
                        break;
                    case 3:
                        lib_1.Lib.errMessage((0, lib_1.text)("text.boss.west"));
                        break;
                }
                setTimeout(function () {
                    if (!attack_flag) { // Player get hurt
                        player_heart -= 2;
                        lib_1.Lib.warnMessage((0, lib_1.text)("message.hurt").replace("%s", "2"));
                        updatePanel();
                        if (player_heart <= 0) {
                            _this.playerDeath(boss.name);
                        }
                    }
                    else {
                        attack_flag = false;
                    }
                }, 1000);
            }, 2000);
        }
    };
    /**================================================================================
     * Functions
     * ================================================================================
     */
    Game.prototype.giveLevel = function (lvl) {
        this.level += lvl;
        this.level = parseFloat(this.level.toFixed(2));
        lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("message.got_level").replace("%s", lvl.toString()), "color: lightgreen", "color: white");
        if (this.level == 50) { // Big code mob
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("message.unlock").replace("%s", "50").replace("%e", (0, lib_1.text)("mob.large_code_mob")), "color: lightgreen", "color: white", "font-weight: bold", "font-weight: 400");
        }
        else if (this.level >= 99) { // BOSS code mob
            lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("message.unlock").replace("%s", "99").replace("%e", (0, lib_1.text)("mob.super_code_mob")), "color: lightgreen", "color: white", "font-weight: bold", "font-weight: 400");
            clearInterval(this.mobSpawner);
            this.bossMission();
        }
        else if (this.level == 100) { // Game Over
            this.gameOver();
        }
        this.updateInfoPanel();
    };
    Game.prototype.giveMoney = function (money) {
        this.money += money;
        lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("message.got_money").replace("%s", money.toString()), "color: lightblue", "color: white");
        this.updateInfoPanel();
    };
    Game.prototype.giveWeapon = function (id) {
        this.weapon = lib_1.Lib.getWeapon(id);
        lib_1.Lib.npcSpeak((0, lib_1.text)("npc.system"), (0, lib_1.text)("message.got_weapon").replace("%s", this.weapon.name).replace("%e", this.weapon.level.toString()), "color: yellow", "color: white");
        this.updateInfoPanel();
    };
    Game.prototype.spawnMob = function () {
        var dir = nriot_utils_1.NUtils.getRandom(1, 4); /* 1 up, 2 left, 3 down, 4 right */
        var id = this.level <= 50 ? nriot_utils_1.NUtils.getRandom(1, 2) : nriot_utils_1.NUtils.getRandom(1, 3);
        var mob = lib_1.Lib.getMob(id);
        var entity = { dir: dir, id: id, type: mob.type, name: mob.name, heart: mob.heart };
        this.mobs.push(entity);
        switch (dir) {
            case types_1.Direction.UP:
                lib_1.Lib.warnMessage((0, lib_1.text)("text.mob.north").replace("%s", mob.name));
                break;
            case types_1.Direction.DOWN:
                lib_1.Lib.warnMessage((0, lib_1.text)("text.mob.south").replace("%s", mob.name));
                break;
            case types_1.Direction.LEFT:
                lib_1.Lib.warnMessage((0, lib_1.text)("text.mob.west").replace("%s", mob.name));
                break;
            case types_1.Direction.RIGHT:
                lib_1.Lib.warnMessage((0, lib_1.text)("text.mob.east").replace("%s", mob.name));
                break;
        }
    };
    Game.prototype.killMobLevel = function (id) {
        switch (id) {
            case 1:
                this.giveLevel(0.1);
                var rm = nriot_utils_1.NUtils.getRandom(1, 2);
                if (rm == 2)
                    this.giveMoney(1);
                break;
            case 2:
                this.giveLevel(0.3);
                var rm = nriot_utils_1.NUtils.getRandom(1, 2);
                if (rm == 2)
                    this.giveMoney(3);
                break;
            case 3:
                this.giveLevel(0.5);
                var rm = nriot_utils_1.NUtils.getRandom(1, 2);
                if (rm == 2)
                    this.giveMoney(5);
                break;
        }
    };
    Game.prototype.updateInfoPanel = function () {
        document.getElementById("tips").style.display = "none";
        vars_1.info_panel.PANEL.style.display = "block";
        vars_1.info_panel.LVL.innerHTML = this.level.toString();
        vars_1.info_panel.BAL.innerHTML = this.money + "$";
        vars_1.info_panel.WEAPON.innerHTML = this.weapon.name + "(level " + this.weapon.level + ")";
        // Save data
        DataStorage_1["default"].get().setSave({
            money: this.money,
            level: this.level,
            weapon: this.weapon,
            armor: this.armor
        });
    };
    return Game;
}());
exports["default"] = Game;
