"use strict";
exports.__esModule = true;
exports.boss_panel = exports.info_panel = exports.version = void 0;
exports.version = "0.3.0";
exports.info_panel = {
    PANEL: document.getElementById("panel"),
    LVL: document.getElementById("i-lvl"),
    BAL: document.getElementById("i-bal"),
    WEAPON: document.getElementById("i-wp")
};
exports.boss_panel = {
    PANEL: document.getElementById("boss-panel"),
    P_HEART: document.getElementById("p-heart"),
    B_HEART: document.getElementById("b-heart")
};
