"use strict";
// Basic Types
exports.__esModule = true;
exports.ArmorKind = exports.Direction = exports.Position = exports.Space = exports.Key = void 0;
var Key;
(function (Key) {
    Key["W"] = "w";
    Key["A"] = "a";
    Key["S"] = "s";
    Key["D"] = "d";
})(Key = exports.Key || (exports.Key = {}));
var Space;
(function (Space) {
    // The beginning of the whole game
    Space["BEGIN"] = "begin";
    // The ending of the whole game
    Space["END"] = "end";
    // Player death
    Space["DEATH"] = "death";
    // The hub of the other spaces (The player can go to the other spaces by it)
    Space["MAIN"] = "main";
    // The practice area (The player can kill mob and earn money in it)
    Space["PRACTICE"] = "prtc";
    // The weapon shop (The player can buy something better in it)
    Space["SHOP"] = "shop";
    // The boss part of the game (After the boss died, the player will be teleported to "END" space)
    Space["BOSS"] = "boss";
})(Space = exports.Space || (exports.Space = {}));
var Position;
(function (Position) {
    Position[Position["NORTH"] = 0] = "NORTH";
    Position[Position["EAST"] = 1] = "EAST";
    Position[Position["SOUTH"] = 2] = "SOUTH";
    Position[Position["WEST"] = 3] = "WEST";
})(Position = exports.Position || (exports.Position = {}));
var Direction;
(function (Direction) {
    Direction[Direction["UP"] = 1] = "UP";
    Direction[Direction["DOWN"] = 2] = "DOWN";
    Direction[Direction["LEFT"] = 3] = "LEFT";
    Direction[Direction["RIGHT"] = 4] = "RIGHT";
})(Direction = exports.Direction || (exports.Direction = {}));
var ArmorKind;
(function (ArmorKind) {
    ArmorKind[ArmorKind["HEAD"] = 0] = "HEAD";
    ArmorKind[ArmorKind["CHEST"] = 1] = "CHEST";
    ArmorKind[ArmorKind["BOOTS"] = 2] = "BOOTS";
})(ArmorKind = exports.ArmorKind || (exports.ArmorKind = {}));
