"use strict";
exports.__esModule = true;
var lib_1 = require("./lib");
var vars_1 = require("./types/vars");
var Game_1 = require("./Game");
var DataStorage_1 = require("./DataStorage");
var Main = /** @class */ (function () {
    function Main() {
        this.init();
        console.log("\n%c".concat((0, lib_1.text)("game.name"), "\nBy NriotHrreion\n%c================\n\n%c").concat((0, lib_1.text)("tip.start"), "\n"), "font-weight: bold", "font-weight: 300", "font-weight: 400; color: #0e97ec");
    }
    Main.prototype.init = function () {
        var _this = this;
        this.game = new Game_1["default"]();
        console.clear = undefined;
        lib_1.Lib.isGameBegin = false;
        lib_1.Lib.setCommand("start", function () {
            _this.game.gameBegin();
            _this.game.updateInfoPanel();
            lib_1.Lib.isGameBegin = true;
        });
        lib_1.Lib.setCommand("help", function () {
            lib_1.Lib.groupMessage((0, lib_1.text)("group.commands"), [
                (0, lib_1.text)("command.help.start"),
                (0, lib_1.text)("command.help.info"),
                (0, lib_1.text)("command.help.beg_prtc"),
                (0, lib_1.text)("command.help.g_main"),
                (0, lib_1.text)("command.help.delsave")
            ]);
        });
        lib_1.Lib.setCommand("info", function () {
            lib_1.Lib.groupMessage((0, lib_1.text)("group.info"), [
                (0, lib_1.text)("command.info.level") + ": " + _this.game.level,
                (0, lib_1.text)("command.info.wallet") + ": " + _this.game.money + "$",
                (0, lib_1.text)("command.info.current_weapon") + ": " + _this.game.weapon.name + "(level " + _this.game.weapon.level + ")"
            ]);
        });
        lib_1.Lib.setCommand("delsave", function () {
            DataStorage_1["default"].get().deleteSave();
            window.location.reload();
        });
        console.log("%cVersion: %c" + vars_1.version, "color: yellow", "");
        console.log("%cLanguage detected: %c" + window.navigator.language, "color: yellow", "");
        console.log("%cGithub: %chttps://github.com/NriotHrreion/Console-game", "color: yellow", "");
    };
    return Main;
}());
exports["default"] = Main;
