"use strict";
exports.__esModule = true;
exports.text = exports.Lib = void 0;
var types_1 = require("./types");
var nriot_utils_1 = require("nriot-utils");
/**
 * Import Languages
 */
var zh_cn_1 = require("./lang/zh_cn");
var en_1 = require("./lang/en");
var languages = {
    zh_cn: zh_cn_1["default"],
    en: en_1["default"]
};
function $(key) {
    try {
        return languages[window.navigator.language.replace("-", "_").toLowerCase()][key];
    }
    catch (e) {
        return languages["zh_cn"][key];
    }
}
var assets = {
    WEAPONS: [
        { id: 0, name: $("text.empty"), level: 0, att: 0 },
        { id: 1, name: $("weapon.iron_sword"), level: 0, att: 3 },
        { id: 2, name: $("weapon.symbol"), level: 1, att: 4 },
        { id: 3, name: $("weapon.bloodthirsty"), level: 3, att: 6 },
        { id: 4, name: $("weapon.bit_sword"), level: 7, att: 10 },
        { id: 5, name: $("weapon.meniscus"), level: 9, att: 12 }
    ],
    MOBS: [
        { id: 1, type: "small_code_mob", name: $("mob.small_code_mob"), heart: 10 },
        { id: 2, type: "medium_code_mob", name: $("mob.medium_code_mob"), heart: 20 },
        { id: 3, type: "large_code_mob", name: $("mob.large_code_mob"), heart: 40 },
        { id: 4, type: "super_code_mob", name: $("mob.super_code_mob"), heart: 100 }
    ],
    ITEMS: [
        { name: $("weapon.symbol") + "(level 1) | 30$", price: 30, id: 2 },
        { name: $("weapon.bloodthirsty") + "(level 3) | 120$", price: 120, id: 3 },
        { name: $("weapon.bit_sword") + "(level 7) | 270$", price: 270, id: 4 },
        { name: $("weapon.meniscus") + "(level 9) | 340$", price: 340, id: 5 },
    ]
};
var Library = /** @class */ (function () {
    function Library() {
        this.isGameBegin = false;
        /**
         * This is in order to prevent the user press the `W` `A` `S` `D` keys at the same time.
         * If two items in `keyboardStatus` is true, the press won't take effect. (The player won't cause damage to the mob)
         */
        this.keyboardStatus = {
            W: false,
            A: false,
            S: false,
            D: false
        };
    }
    Library.prototype.randomBoolean = function (p) {
        return nriot_utils_1.NUtils.getRandom(0, p || 1) === 0;
    };
    Library.prototype.setCommand = function (command, func) {
        try {
            window[command] = command;
            Object.defineProperty(window, command, {
                get: func,
                enumerable: true
            });
        }
        catch (e) {
            // Do nothing
        }
    };
    Library.prototype.delCommand = function (command) {
        delete window[command];
    };
    Library.prototype.getWeapon = function (id) {
        var weapons = assets.WEAPONS;
        for (var i in weapons) {
            if (weapons[i].id == id) {
                return weapons[i];
            }
        }
    };
    Library.prototype.getMob = function (id) {
        var mobs = assets.MOBS;
        for (var i in mobs) {
            if (mobs[i].id == id) {
                return mobs[i];
            }
        }
    };
    Library.prototype.getItemList = function () {
        return assets.ITEMS;
    };
    Library.prototype.npcSpeak = function (name, content) {
        var style = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            style[_i - 2] = arguments[_i];
        }
        var args = '';
        for (var i in style) {
            if (typeof style[i] === "string") {
                args += ', "' + style[i] + '"';
            }
        }
        var log = "console.log(\"[%c" + name + "%c] " + content + "\", \"font-weight: bold\", \"font-weight: 400\"" + args + ")";
        window.eval(log);
    };
    Library.prototype.warnMessage = function (content) {
        console.warn("[%c" + $("console.warn") + "%c] " + content, "font-weight: bold", "font-weight: 400");
    };
    Library.prototype.errMessage = function (content) {
        console.error("[%c" + $("console.err") + "%c] " + content, "font-weight: bold", "font-weight: 400");
    };
    Library.prototype.groupMessage = function (groupName, groupContent) {
        if (typeof groupContent === "function") {
            groupContent = groupContent();
        }
        console.group(groupName);
        for (var i in groupContent) {
            if (typeof groupContent[i] === "string") {
                console.log(groupContent[i]);
            }
        }
        console.groupEnd();
    };
    Library.prototype.tips = function (content) {
        setTimeout(function () { console.log("%c" + content, "font-style: italic; color: gray"); }, 400);
    };
    Library.prototype.keysListener = function (handles) {
        var _this = this;
        document.onkeydown = function (e) {
            switch (e.key) {
                case types_1.Key.W:
                    e.preventDefault();
                    _this.keyboardStatus.W = true;
                    if (_this.keyboardStatus.W && _this.keyboardStatus.A === _this.keyboardStatus.S === _this.keyboardStatus.D === false) {
                        handles.W();
                    }
                    break;
                case types_1.Key.A:
                    e.preventDefault();
                    _this.keyboardStatus.A = true;
                    if (_this.keyboardStatus.A && _this.keyboardStatus.W === _this.keyboardStatus.S === _this.keyboardStatus.D === false) {
                        handles.A();
                    }
                    break;
                case types_1.Key.S:
                    e.preventDefault();
                    _this.keyboardStatus.S = true;
                    if (_this.keyboardStatus.S && _this.keyboardStatus.A === _this.keyboardStatus.W === _this.keyboardStatus.D === false) {
                        handles.S();
                    }
                    break;
                case types_1.Key.D:
                    e.preventDefault();
                    _this.keyboardStatus.D = true;
                    if (_this.keyboardStatus.D && _this.keyboardStatus.A === _this.keyboardStatus.S === _this.keyboardStatus.W === false) {
                        handles.D();
                    }
                    break;
            }
        };
        document.onkeyup = function (e) {
            switch (e.key) {
                case types_1.Key.W:
                    e.preventDefault();
                    _this.keyboardStatus.W = false;
                    break;
                case types_1.Key.A:
                    e.preventDefault();
                    _this.keyboardStatus.A = false;
                    break;
                case types_1.Key.S:
                    e.preventDefault();
                    _this.keyboardStatus.S = false;
                    break;
                case types_1.Key.D:
                    e.preventDefault();
                    _this.keyboardStatus.D = false;
                    break;
            }
        };
    };
    return Library;
}());
exports.Lib = new Library();
exports.text = $;
